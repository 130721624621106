<template>
  <div class="order">
    <page-nav title="我的订单" />
    <van-tabs v-model="tabActive" color="#ff0069" @change="onChangeTab">
      <van-tab title="全部" name="">
        
      </van-tab>
      <van-tab title="待付款" name="waitPay">
        
      </van-tab>
      <van-tab title="待发货" name="waitSend">
        
      </van-tab>
      <van-tab title="待收货" name="waitConfirm">
        
      </van-tab>
    </van-tabs>
    <van-list
      v-model="loading"
      :finished="finished"
      @load="fetchList"
    >
      <div class="order-list">
        <div v-for="item in list" :key="item.id" class="order-item">
          <div class="order-header">
            <img class="store-logo" :src="item.storeLogoImg" />
            <div class="store-name van-ellipsis">{{item.storeTitle}}</div>
            <div v-if="item.status == 1" class="order-state">待付款</div>
            <div v-else-if="item.status == 2" class="order-state">待发货</div>
            <div v-else-if="item.status == 3" class="order-state">待收货</div>
            <div v-else-if="item.status == 4" class="order-state">待评价</div>
            <div v-else-if="item.status == 0" class="order-state">已取消</div>
            <div v-else-if="item.status == 9" class="order-state">已完成</div>
          </div>
          <div class="order-content">
            <div v-for="prod in item.orderProductList" :key="prod.productId" class="product-info">
              <img class="cover" :src="prod.titleImg" />
              <div class="title van-multi-ellipsis--l2">{{prod.title}}</div>
              <div class="count">x{{prod.amount}}</div>
            </div>
            <div class="total-bar">
              <span v-if="item.status == 1 || item.status == 0">应付</span>
              <span v-else>实付</span>
              <span class="price">￥{{item.totalPrice}}</span>
            </div>
          </div>
          <div class="order-btn"></div>
        </div>
      </div>
    </van-list>
    <van-empty v-show="!loading && list.length == 0" description="暂无相关订单" />
    <go-top />
  </div>
</template>

<script>
import Vue from 'vue';
import { Toast, Lazyload } from 'vant';
import Fetch from '@/common/fetch';
import { BuyerGetOrderList } from '@/common/interface';
import GoTop from '../components/GoTop.vue';
import PageNav from '../components/PageNav.vue';

Vue.use(Toast);
Vue.use(Lazyload);

export default {
  name: "Order",
  components: { GoTop, PageNav },
  data() {
    return {
      tabActive: '',
      curPage: 1,
      loading: false,
      finished: false,
      list: [],
    };
  },

  mounted() {
    const { tab = '' } = this.$route.query;
    this.tabActive = tab;
  },

  methods: {
    onChangeTab(name) {
      if (name == '') {
        this.$router.replace('/order');
      } else {
        this.$router.replace('/order?tab=' + name);
      }
      this.curPage = 1;
      this.fetchList();
    },

    fetchList() {
      const page = this.curPage;

      let status = 0;
      if (this.tabActive == 'waitPay') {  // 待付款
        status = 1;
      } else if (this.tabActive == 'waitSend') { // 代发货
        status = 2;
      } else if (this.tabActive == 'waitConfirm') { //待收货
        status = 3;
      }

      const params = {
        pageNum: page,
        pageSize: 20,
        status,
      };

      Fetch.post(BuyerGetOrderList, params).then((res) => {
        const resList = res.list || [];
        const finished = resList.length < 20;
        const newList = page == 1 ? resList : this.list.concat(resList);
        this.curPage = page + 1;
        this.list = newList;
        this.loading = false;
        this.finished = finished;
      }).catch((err) => {
        Toast(err.msg || '请求失败');
      });
    },

  },
}
</script>

<style lang="scss" scoped>
.order-item {
  margin: 15px 10px;
  padding: 10px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 5%);
  .order-header {
    line-height: 24px;
    position: relative;
    font-family: PingFang-SC-Medium;
    display: flex;
    align-items: center;
    .store-logo {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
    .store-name {
      max-width: 90%;
      font-size: 16px;
      color: #333;
      padding-right: 12px;
      flex: 1;
    }
    .order-state {
      font-size: 14px;
      color: #999;
      flex-shrink: 0;
    }
  }
  .order-content {
    .product-info {
      margin-top: 8px;
      display: flex;
      overflow: hidden;
      .cover {
        width: 75px;
        height: 75px;
        margin-right: 10px;
        border-radius: 6px;
        flex-shrink: 0;
      }
      .title {
        flex: 1;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #333;
        line-height: 21px;
        align-self: center;
      }
      .count {
        margin-left: 8px;
        align-self: center;
        color: #999;
      }
    }
    .total-bar {
      margin-top: 5px;
      position: relative;
      display: flex;
      justify-content: flex-end;
      margin-left: 10px;
      font-size: 14px;
      color: #333;
      line-height: 21px;
      .price {
        font-size: 16px;
        color: #151515;
        vertical-align: bottom;
        font-weight: 600;
      }
    }
  }
}
</style>